html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

.container {
  position: relative;
  background-color: #f8f8f8;
}

.visualization-svg {
  background-color: #f8f8f8 !important;
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  padding: 15px;
}

.logo {
  display: flex;
}

.search {
  position: absolute;
  padding: 0 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.search-input {
  flex: 1;
  height: 29px;
  padding: 0 10px;
  background-color: white;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  width: 500px;
  margin-left: 10px;
  background-color: #eee;
}

.search-icon {
  margin-left: 10px;
  height: 20px;
}

.body {
  margin-top: 100px;
  background-color: #f8f8f8;
}

.autocomplete {
  position: absolute;
  top: calc(100% + 20px);
  left: 0;
  width: 100%;
  padding: 10px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  border-radius: 5px;
}

.autocomplete-item {
  display: block;
  padding: 10px 20px;
  background-color: white;
  border: none;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  color: black;
  width: 100%;
  font-size: inherit;
}

.autocomplete-item:hover {
  background-color: #eee;
}

.autocomplete-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.selected-item {
  font-weight: bold;
}

.detail {
  position: fixed;
  top: 100px;
  right: 50px;
  height: calc(100vh - 150px);
  width: 500px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.detail-header {
  margin-bottom: 20px;
}

.detail-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.detail-body {
  flex: 1;
}

.detail-section {
  margin-bottom: 20px;
}

.detail-section-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.detail-section-body {
  line-height: 1.5;
}

.list-item text {
  cursor: pointer;
}

.detail-related-item {
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  display: inline;
  cursor: pointer;
}
